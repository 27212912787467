import { combineReducers, configureStore } from '@reduxjs/toolkit';
import objectFiltersSlice from './ObjectAnalyser/objectAnalyserSlice';
import accountStatsSlice from './AccountStats/accountStatsSlice';
import { activeUsersSlice, activeUserConfigSlice } from './ActiveUsers/activeUsersSlice';
import { versionsSlice, editionsSlice, versionKpiScoreSlice } from './Versions/versionsSlice';
import { customObjectSlice, customObjectPinningData } from './CustomObjects/customObjectSlice';
import { generalDetailSlice, allRemarksSlice } from './GeneralDetails/generalDetailSlice';
import { featureMatrixSlice } from './FeatureMatrix/featureMatrixSlice';
import { accountsListSlice, accountVisibilitySlice, licenseUtilKpiScoreSlice } from './AccountList/accountListSlice';
import { packageList, accountPackageList, packageDetailList } from './Package/packageSlice';
import {
  featureManagerList,
  featuresList,
  featureDetailList,
  customEntityList,
} from './FeatureManager/featureManagerSlice';
import { featureUsageAccountsSlice, featureUsageSlice, topFeaturesSlice } from './FeatureUsage/featureUsageSlice';
import { accCountByCountryLang, countryKpiScoreSlice } from './Geography/geographySlice';
import {
  healthInsightsDetailsSlice,
  healthInsightsSlice,
  healthScoreExplainSlice,
  healthScoreGraphSlice,
  kpiScoreSizeSlice,
} from './HealthScore/HealthScoreSlice';
import accountHealthScore from './AccountHealthScore/accountHealthScoreSlice';
import snackBarSlice from './SnackBar/snackBarSlice';
import { authSlice, userManagementSlice } from './Auth/authSlice';
import { eventTimelineSlice } from './EventTimeline/EventTimelineSlice';
import { healthScoreTrendsSlice } from './HealthScoreTrends/healthScoreTrendsSlice';
import { tenantDetailsSlice, tenantSlice } from './Tenant/tenantSlice';
import customFilterViewSlice from './CustomFilterView/customFilterViewSlice';
import { pboConnectionDetailsSlice, pboConnectionSlice } from './PboConnection/PboConnectionSlice';
import { getCustomAlertSlice, saveCustomAlertSlice, watchListAccountsSlice } from './CustomAlert/customAlertSlice';
import { getNotificationSettingsSlice, saveNotificationSettingsSlice } from './NotificationSettings/notificationSettingsSlice';
import { highRiskAccountConfigSlice } from './Configuration/configurationSlice';
import { logAnalyzerDetailsSlice, logAnalyzerSlice } from './LogAnalyzer/logAnalyzerSlice';
import { featureParameterSlice } from './FeatureParameter/FeatureParameterSlice';
import { scoreControlsSlice } from './GlobalState/globalState.slice';
import tourSlice from './TourGuide/tourGuideSlice';

const appReducer = combineReducers({
  auth: authSlice.reducer,
  tenants: tenantSlice.reducer,
  tenantDetails: tenantDetailsSlice.reducer,
  objectFilters: objectFiltersSlice.reducer,
  accountStats: accountStatsSlice.reducer,
  activeUsers: activeUsersSlice.reducer,
  versions: versionsSlice.reducer,
  editions: editionsSlice.reducer,
  customObjects: customObjectSlice.reducer,
  generalDetails: generalDetailSlice.reducer,
  topFeatures: topFeaturesSlice.reducer,
  featureUsage: featureUsageSlice.reducer,
  featureMatrix: featureMatrixSlice.reducer,
  featureUsageAccounts: featureUsageAccountsSlice.reducer,
  featuresList: featuresList.reducer,
  accountsList: accountsListSlice.reducer,
  accountVisibility: accountVisibilitySlice.reducer,
  packageList: packageList.reducer,
  accountPackageList: accountPackageList.reducer,
  packageDetailList: packageDetailList.reducer,
  featureManagerList: featureManagerList.reducer,
  featureDetailList: featureDetailList.reducer,
  customEntityList: customEntityList.reducer,
  accCountByCountryLang: accCountByCountryLang.reducer,
  customObjectPinningData: customObjectPinningData.reducer,
  healthInsights: healthInsightsSlice.reducer,
  kpiScoreSize: kpiScoreSizeSlice.reducer,
  healthInsightsDetails: healthInsightsDetailsSlice.reducer,
  accountHealthScore: accountHealthScore.reducer,
  healthScoreGraph: healthScoreGraphSlice.reducer,
  healthScoreExplain: healthScoreExplainSlice.reducer,
  activeUserConfig: activeUserConfigSlice.reducer,
  snackbar: snackBarSlice.reducer,
  eventTimeline: eventTimelineSlice.reducer,
  healthScoreTrends: healthScoreTrendsSlice.reducer,
  userList: userManagementSlice.reducer,
  customFilterView: customFilterViewSlice.reducer,
  pboConnection: pboConnectionSlice.reducer,
  pboConnectionDetails: pboConnectionDetailsSlice.reducer,
  watchListAccounts: watchListAccountsSlice.reducer,
  getCustomAlertDetails: getCustomAlertSlice.reducer,
  saveCustomAlertDetails: saveCustomAlertSlice.reducer,
  getNotificationSettingsSlice: getNotificationSettingsSlice.reducer,
  saveNotificationSettingsSlice: saveNotificationSettingsSlice.reducer,
  highRiskAccountConfig: highRiskAccountConfigSlice.reducer,
  logAnalyzer: logAnalyzerSlice.reducer,
  logAnalyzerDetails: logAnalyzerDetailsSlice.reducer,
  versionKpiScore: versionKpiScoreSlice.reducer,
  countryKpiScore: countryKpiScoreSlice.reducer,
  licenseUtilKpiScore: licenseUtilKpiScoreSlice.reducer,
  allRemarks: allRemarksSlice.reducer,
  featureParameter: featureParameterSlice.reducer,
  // global state
  scoreControls: scoreControlsSlice.reducer,
  tourGuide: tourSlice.reducer,
});

const reducerProxy = (state, action) => {
  if (action.type === 'resetAll') {
    // Preserve tourGuide state
    const tourGuideState = state?.tourGuide;

    // Reset all other states except tourGuide
    const newState = appReducer(undefined, action);

    // Reassign preserved tourGuide state
    return {
      ...newState,
      tourGuide: tourGuideState,
    };
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: reducerProxy,
});

export default store;
