import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Graph } from '../../components/Graph';
import { withLoadingAndOverlay } from '../../hoc';

function KpiScoreSizeBubble({ data: kpiScoreSize = [] }) {
  const navigate = useNavigate();

  const labels = useMemo(() => {
    const array = [''];
    kpiScoreSize.forEach((obj) => {
      array.push(obj.x);
    });
    return array;
  }, [kpiScoreSize]);

  const datasets = [
    {
      data: kpiScoreSize,
      backgroundColor: [
        '#e74c3c', '#ff914d', '#f3bc00', '#9b59b6', '#4482FF',
        '#294E99', '#31e0c4', '#00af82', '#2ecc71', '#0075a4',
      ],
      borderColor: [
        '#e74c3c', '#ff914d', '#f3bc00', '#9b59b6', '#4482FF',
        '#294E99', '#31e0c4', '#00af82', '#2ecc71', '#0075a4',
      ],
    },
  ];

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        type: 'category',
        labels,
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        labels: false,
        title: {
          display: true,
        },
      },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (event, chartElement) => {
      const { index } = chartElement.length && chartElement[0];
      if (index || index === 0) {
        const tooltipEl = document.getElementById('chartjs-tooltip');
        tooltipEl.style.opacity = '0';
        const bubbleValue = kpiScoreSize && kpiScoreSize.length ? kpiScoreSize[index] : 0;
        if (bubbleValue && bubbleValue.x) {
          navigate('/account-list', { state: { filters: { licenceAssignment: [bubbleValue.x], licenceStatus: ['Active'], accountVisibility: true } } });
        }
      }
    },
    responsive: true,
    plugins: {
      legend: false,
      datalabels: {
        display: false, // Disable data labels on bubbles
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        external(context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('below', 'no-transform');
          const { $context = {} } = tooltipModel;
          const { tooltipItems = [] } = $context;
          if (tooltipItems && tooltipItems.length) {
            const label = tooltipItems?.[0]?.raw?.x ?? '';
            const kpiScore = tooltipItems?.[0]?.raw?.y ?? 0;
            const account = tooltipItems?.[0]?.raw?.seat ?? 0;
            const innerHTML = `
              <div style="width: 150px; background-color: rgba(0, 0, 0, 0.7); border-radius: 5px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); padding: 3px;">
                  <div style="padding: 3px; background-color: transparent; border-top-left-radius: 5px; border-top-right-radius: 5px;">
                      <span style="color: white; font-size: 15px;">${label}</span>
                  </div>
                  <hr style="margin: 0; border-color: white;">
                  <div style="padding: 3px;">
                  <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #4482FF;"></span>
                  Account : ${account}
                </p>
                <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #f28e00;"></span>
                  Avg. KPI Score : ${kpiScore}
                </p>
                  </div>
              </div>`;

            tooltipEl.querySelector('table').innerHTML = innerHTML;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';

          const tooltipWidth = tooltipEl.offsetWidth;
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipWidth > position.right) {
            left -= (left + tooltipWidth - position.right);
          }

          tooltipEl.style.left = `${left}px`;

          tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
          tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },

      },
    },
  };

  return (
    <Graph
      type="bubble"
      labels={labels}
      datasets={datasets}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
}

export default withLoadingAndOverlay(KpiScoreSizeBubble);
