import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PinnedFeatureUsage } from '../../modules/Accounts';
import usePersona from '../../modules/Dashboard/components/usePersona';
import useToggle from '../../hooks/useToggle';
import useAuthorization from '../../hooks/useAuthorization';
import useActiveUsers from '../../modules/Accounts/components/ActiveUsers/useActiveUsers';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { fetchTenantInfo } from '../../features/Tenant/tenantAction';
import { getAccountsList, getActiveUsers, getLicenseUtilKpiScore } from '../../features';
import { getCustomAlertDetails } from '../../features/CustomAlert/customAlertAction';
import { DASHBOARD_CARDS_ID, DASHBOARD_PERSONA } from '../../utils/constants';
import { DashboardHealthScore } from '../DashboardHealthScore';
import ActiveUsers from '../../modules/Accounts/components/ActiveUsers/ActiveUsers';
import VersionsCard from '../../modules/Versions/components/VersionsCard/VersionsCard';
import { TopFeatures } from '../TopFeatures';
import { LicenseUtilization } from '../AccountLicense';
import LicenseUtilizationKpiScore from '../AccountLicense/LicenseUtilizationKpiScore';

export default function useDashboard() {
  const [refreshAccountList, setRefreshAccountList] = useToggle();
  const [refreshUsers, setRefreshUsers] = useToggle();
  const [refreshLicenseUtilKpiScore, setRefreshLicenseUtilKpiScore] = useToggle();
  const { userInfo } = useAuthorization();
  const { activeUsers } = useActiveUsers();
  const { packageList, activePackage, handleDropdownChange } = usePackageSelection();
  const { personaList, selectedPersona, handlePersonaChange } = usePersona();

  const dispatch = useDispatch();
  const location = useLocation();
  const startTourState = location?.state?.startTour ?? false;

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
      dispatch(getCustomAlertDetails({ pkgId: activePackage.packageId }));
    }
  }, [activePackage?.packageId, refreshAccountList]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getLicenseUtilKpiScore({ pkgId: activePackage.packageId }));
    }
  }, [activePackage?.packageId, refreshLicenseUtilKpiScore]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getActiveUsers({ page: 'dashboard', packageId: activePackage.packageId }));
    }
  }, [refreshUsers, activePackage?.packageId]);

  useEffect(() => {
    if (userInfo?.tenantId) {
      dispatch(fetchTenantInfo({ tenantId: userInfo.tenantId }));
    }
  }, [userInfo?.tenantId]);

  const visibleBoxIds = DASHBOARD_PERSONA[selectedPersona] || [];

  const boxComponents = {
    [DASHBOARD_CARDS_ID.HIGH_RISK_ACCOUNTS]: <DashboardHealthScore type="riskAccounts" title="High-Risk Accounts" refresh={setRefreshAccountList} />,
    [DASHBOARD_CARDS_ID.EXPIRING_ACCOUNTS]: <DashboardHealthScore type="expiredAccounts" title="Accounts Expiring" refresh={setRefreshAccountList} />,
    [DASHBOARD_CARDS_ID.DECLINING_ACCOUNTS]: <DashboardHealthScore type="decliningAccounts" title="Declining KPI Scores" refresh={setRefreshAccountList} />,
    [DASHBOARD_CARDS_ID.TRENDING_ACCOUNTS]: <DashboardHealthScore type="trendingAccounts" title="Trending KPI Scores" refresh={setRefreshAccountList} />,
    [DASHBOARD_CARDS_ID.MONTHLY_ACTIVE_USERS]: <ActiveUsers viewOption="month" title="Monthly Active Users" resultSet={activeUsers.monthObject} error={activeUsers.error} loading={activeUsers.loading} orgId="all" setRefreshUsers={setRefreshUsers} page="dashboard" />,
    [DASHBOARD_CARDS_ID.WEEKLY_ACTIVE_USERS]: <ActiveUsers viewOption="week" title="Weekly Active Users" resultSet={activeUsers.weekObject} error={activeUsers.error} loading={activeUsers.loading} orgId="all" setRefreshUsers={setRefreshUsers} page="dashboard" />,
    [DASHBOARD_CARDS_ID.DAILY_ACTIVE_USERS]: <ActiveUsers viewOption="day" title="Daily Active Users" resultSet={activeUsers.dayObject} error={activeUsers.error} loading={activeUsers.loading} orgId="all" setRefreshUsers={setRefreshUsers} page="dashboard" />,
    [DASHBOARD_CARDS_ID.VERSIONS]: <VersionsCard activePackage={activePackage} />,
    [DASHBOARD_CARDS_ID.TOP_FEATURES]: <TopFeatures activePackage={activePackage} />,
    [DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT]: <LicenseUtilization title="Account License Assignment" refresh={setRefreshAccountList} />,
    [DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT_VS_KPI]: <LicenseUtilizationKpiScore title="License Assignment vs Avg. KPI Score" refresh={setRefreshLicenseUtilKpiScore} />,
    [DASHBOARD_CARDS_ID.PINNED_FEATURES]: <PinnedFeatureUsage activePackage={activePackage} tenantId={userInfo?.tenantId} />,
  };

  return {
    personaList,
    selectedPersona,
    handlePersonaChange,
    visibleBoxIds,
    boxComponents,
    packageList,
    activePackage,
    handleDropdownChange,
    startTourState,
  };
}
