import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AccountHealthScore, ActiveUsers, EngagementSection, GeneralDetailSection, PinnedFeatureUsage, TopCustomObject,
} from '../../modules/Accounts';
import { FeatureParameter } from '../../modules/Accounts/components/FeatureParameter';
import { ACCOUNT_CARDS_ID, ACCOUNT_PERSONA } from '../../utils/constants';
import { TopFeatures } from '../TopFeatures';
import useActiveUsers from '../../modules/Accounts/components/ActiveUsers/useActiveUsers';

const useAccountDetail = (props = {}) => {
  const {
    selectedPersona,
    setRefreshUsers,
    permissions,
    isShareableScreens,
    tenantId,
    orgId,
    accountName,
    accountOrgId,
    accountPackage,
    activePackage,
  } = props;

  const { activeUsers } = useActiveUsers();

  const [customObjectPinningData, setCustomObjectPinningData] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [customObject, setCustomObject] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [generalDetail, setGeneralDetail] = useState({
    data: {},
    error: '',
    loading: true,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: 'UniqueUserCount',
      sort: 'asc',
    },
  ]);
  const [visibility, setVisibility] = useState(true);

  const pinningData = useSelector((state) => state.customObjectPinningData);

  const setCustomObjectPinning = () => {
    const { response, loading, error } = pinningData;
    setCustomObjectPinningData({
      ...customObjectPinningData, data: response || [], loading, error,
    });
  };

  const customObjectsData = useSelector((state) => state.customObjects);

  const setCustomObjects = () => {
    const { response, loading, error } = customObjectsData;
    setCustomObject({
      ...customObject, data: response?.responseArray ?? [], loading, error,
    });
  };

  const generalDetailsData = useSelector((state) => state.generalDetails);

  const setGeneralDetails = () => {
    const { response, loading, error } = generalDetailsData;
    setGeneralDetail({
      ...generalDetail, data: response?.responseData, loading, error,
    });
    setVisibility(response?.responseData?.visibility !== false);
  };

  useEffect(() => {
    setCustomObjects();
  }, [customObjectsData]);

  useEffect(() => {
    setGeneralDetails();
  }, [generalDetailsData]);

  useEffect(() => {
    setCustomObjectPinning();
  }, [pinningData]);

  const visibleBoxIds = ACCOUNT_PERSONA[selectedPersona] || [];

  const boxComponents = {
    [ACCOUNT_CARDS_ID.GENERAL]: <GeneralDetailSection permissions={permissions} generalDetail={generalDetail} isShare={isShareableScreens} />,
    [ACCOUNT_CARDS_ID.ENGAGEMENT]: <EngagementSection monthObject={activeUsers.monthObject} weekObject={activeUsers.weekObject} />,
    [ACCOUNT_CARDS_ID.KPI_SCORES]: <AccountHealthScore isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} permissions={permissions} />,
    [ACCOUNT_CARDS_ID.MAU]: <ActiveUsers viewOption="month" title="Monthly Active Users" resultSet={activeUsers.monthObject} error={activeUsers.error} loading={activeUsers.loading} page="account" more={!isShareableScreens} accountName={accountName} orgId={orgId} setRefreshUsers={setRefreshUsers} />,
    [ACCOUNT_CARDS_ID.WAU]: <ActiveUsers viewOption="week" title="Weekly Active Users" resultSet={activeUsers.weekObject} error={activeUsers.error} loading={activeUsers.loading} page="account" more={!isShareableScreens} accountName={accountName} orgId={orgId} setRefreshUsers={setRefreshUsers} />,
    [ACCOUNT_CARDS_ID.TOP_FEATURES]: <TopFeatures activePackage={activePackage} orgId={orgId} pkgId={accountPackage} accountName={accountName} isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} />,
    [ACCOUNT_CARDS_ID.PINNED_FEATURES]: <PinnedFeatureUsage orgId={orgId} pkgId={accountPackage} activePackage={activePackage} accountName={accountName} isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} />,
    [ACCOUNT_CARDS_ID.FEATURE_PARAMETERS]: <FeatureParameter orgId={orgId} packageId={accountPackage} isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} />,
    [ACCOUNT_CARDS_ID.TOP_CUSTOM_OBJ]: <TopCustomObject moreButton={!isShareableScreens} customObject={customObject} customObjectPinningData={customObjectPinningData} pageSize={5} orgId={orgId} pkgId={accountPackage} accountName={accountName} isShare={isShareableScreens} permissions={permissions} />,
  };

  return {
    sortModel, setSortModel, generalDetail, visibility, setVisibility, visibleBoxIds, boxComponents,
  };
};

export default useAccountDetail;
