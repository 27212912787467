import { withLoadingAndOverlay } from '../../../../hoc';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';

function FeatureParameterCard({ columns, data }) {
  return (
    <CustomDataGrid columns={columns} rows={data} />
  );
}

export default withLoadingAndOverlay(FeatureParameterCard);
