import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getDate } from '../../utils/getDate';
import palette from '../../theme/palette';
import { defaultCategories } from '../AccountList/accountListConfig';

const currentDate = getDate();

const useScoreInsights = () => {
  const accountListData = useSelector((state) => state.accountsList);
  const kpiScoreSize = useSelector((state) => state.kpiScoreSize);
  const adminSettings = useSelector((state) => state.highRiskAccountConfig?.data);

  const navigate = useNavigate();

  // Account Age
  const calculateMonthsSinceInstall = (installDate) => {
    const now = moment();
    const install = moment(installDate);
    return now.diff(install, 'months');
  };

  const accountAgeCategories = useMemo(() => {
    // Find the maximum number of months in the data
    const getMaxMonths = () => {
      return accountListData?.accountsList ? Math.max(...accountListData.accountsList.map((account) => calculateMonthsSinceInstall(account.installDate))) : 12;
    };

    const maxMonths = getMaxMonths();
    const interval = 3; // 3 months interval for quarters
    const categories = {};

    // Create dynamic quarter ranges in intervals of 3 months
    for (let start = 0; start <= maxMonths; start += interval) {
      const end = start + interval - 1;
      categories[`${start + 1}-${end + 1} months`] = 0;
    }

    // Categorize accounts based on the months since install
    accountListData?.accountsList?.forEach((account) => {
      const monthsSinceInstall = calculateMonthsSinceInstall(account.installDate);

      // Find the appropriate quarter for the account's months
      for (let start = 0; start <= maxMonths; start += interval) {
        const end = start + interval - 1;
        if (monthsSinceInstall >= start && monthsSinceInstall <= end) {
          categories[`${start + 1}-${end + 1} months`]++;
          break;
        }
      }
    });

    return categories;
  }, [accountListData?.accountsList]);

  const accountAgeLabels = Object.keys(accountAgeCategories ?? {});

  const accountAgeDatasets = [
    {
      label: 'Accounts',
      borderColor: palette.secondary.mainColor,
      backgroundColor: palette.info.light,
      borderWidth: 2,
      fill: true,
      data: Object.values(accountAgeCategories ?? {}),
    },
  ];

  const accountAgeOptions = {
    scales: {
      x: { grid: { display: false } },
      y: { grid: { display: false } },
    },
    elements: { point: { radius: 0 } },
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
      },
    },
  };

  const isActiveAccount = (account) => {
    const { status, renewalDate, visibility } = account;
    const isActiveStatus = status === 'Active';
    const isNeverExpire = renewalDate === null;
    const isRenewalDateValid = renewalDate && getDate(renewalDate) >= currentDate.setHours(0, 0, 0, 0);
    return visibility && isActiveStatus && (isRenewalDateValid || isNeverExpire);
  };

  // Account Seats
  const seatCategories = useMemo(() => {
    const categoriesList = adminSettings?.customerSizeCategories?.length > 0 ? adminSettings.customerSizeCategories : defaultCategories;
    const categories = categoriesList.reduce((acc, category) => {
      acc[category.title] = 0;
      return acc;
    }, {});

    accountListData?.accountsList?.forEach((account) => {
      const { totalSeats } = account;
      if (isActiveAccount(account)) {
        categoriesList.forEach((category) => {
          const from = parseInt(category.from, 10) || 0;
          const to = parseInt(category.to, 10);

          if (to && totalSeats >= from && totalSeats <= to) {
            categories[category.title]++;
          } else if (from && !to && totalSeats >= from) {
            categories[category.title]++;
          } else if (!from && !to && totalSeats < 0) {
            categories[category.title]++;
          }
        });
      }
    });

    return categories;
  }, [accountListData?.accountsList, adminSettings]);

  const seatsCategoryLabels = Object.keys(seatCategories ?? {});

  const seatsCategoryDatasets = [
    {
      data: Object.values(seatCategories ?? {}),
      name: Object.keys(seatCategories ?? {}),
      hoverOffset: 2,
      backgroundColor: ['rgb(167, 199, 231)', 'rgb(132, 186, 239)', 'rgb(85, 153, 236)', 'rgb(30, 100, 180)', '#00af82'],
      borderColor: ['rgb(167, 199, 231)', 'rgb(132, 186, 239)', 'rgb(85, 153, 236)', 'rgb(30, 100, 180)', '#00af82'],
    },
  ];

  const pieChartOptions = {
    onHover: (event) => {
      event.native.target.style.cursor = 'default';
    },
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (!value) return '';
          const sets = ctx.chart.data.datasets;
          if (sets.indexOf(ctx.dataset) === sets.length - 1) {
            const sum = sets[0].data.reduce((a, b) => a + b, 0);
            const percentage = `${Math.round((value / sum) * 100)}%`;
            return percentage;
          }
          return true;
        },
        color: '#fff',
        display: true,
      },
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          padding: 15,
        },
        onHover(e) {
          const { style } = e.native.target;
          style.cursor = 'pointer';
        },
        onLeave(e) {
          const { style } = e.native.target;
          style.cursor = 'default';
        },
      },
    },
  };

  const handleClick = () => {
    navigate('/configuration');
  };

  return {
    accountAgeLabels,
    accountAgeDatasets,
    accountAgeOptions,
    seatsCategoryLabels,
    seatsCategoryDatasets,
    pieChartOptions,
    handleClick,
    kpiScoreSize,
  };
};

export default useScoreInsights;
