const URL_CONSTANT = {
  API_BASE: 'https://api.isvinsights.ai/',
};

const DEFAULT_FILTER = '- all -';

const DATE_FORMAT = {
  PRIMARY: 'DD-MM-YYYY',
  SECONDARY: 'MM-DD-YYYY',
  MONTH_DATE: 'MM-YYYY',
  DATE_PICKER: 'DD/MM/YYYY',
  MONTH_DATE_GRAPH: "MMM 'YY",
  WEEK_DATE_GRAPH: "DD MMM 'YY",
  DAY_DATE_GRAPH: "DD MMM 'YY",
  DAY_DATE_GRAPH2: "DDMMM'YY",
};

const BAR_GRAPH = {
  NestedFeature: 'rgb(64, 230, 173)',
  CustomObject: '#3366FF',
  LightningPage: 'rgb(167, 199, 231)',
  LightningComponent: 'rgb(25, 25, 112)',
  VisualforcePage: 'rgb(93, 06, 255)',
  CustomInteractionLabel: 'rgb(93, 63, 211)',
  ApexClass: 'rgb(100, 199, 237)',
  ApexTrigger: 'rgb(80, 150, 142)',
  PartialData: 'rgb(255,165,0)',
  CompleteData: '#4482FF',
};

const TITLES = {
  SCORE_INSIGHTS: 'Score Insights',
  HEALTH_SCORE_CONFIGURATION: 'KPI Score Configuration',
  ACTIVE_USER_CONFIGURATION: 'Active User Configuration',
  USER_MANAGER: 'User Manager',
  TENANT_MANAGER: 'Tenant Manager',
  PACKAGE_MANAGER: 'Package Manager',
  PROFILE: 'My Profile',
  SALESFORCE_CONFIG: 'SalesForce Configuration',
  LOG_ANALYZER: 'Log Analyzer',
};

const COLUMNS = {
  Label: 'Label',
  LastModified: 'Last Modified',
  Actions: 'Actions',
};

const BUTTONS = {
  NEW_HEALTH_SCORE: 'New KPI Score Configuration',
  IMPERSONATE_TENANT: 'Impersonate',
  CREATE_NEW_USER: 'Create User',
  CREATE_TENANT: 'Create Tenant',
  LOGOUT: 'Logout',
  NEW_FEATURE: 'Define New Feature',
};

const LICENSE_ASSIGNMENT = {
  SITE_WIDE_LICENSE: 'Site Wide License',
  LESS_25: 'Less than 25%',
  FROM_25_50: '25% - 50%',
  FROM_50_75: '50% - 75%',
  FROM_75_100: '75% - 100%',
};

const MUTEDTEXT = {
  HEALTH_SCORE_CONFIGURATION_LABEL_SUB_TEXT: '90 accounts have this score assigned(view)',
};

const DASHBOARD_CARDS_ID = {
  ACCOUNT_STATS: 'accountStats',
  HIGH_RISK_ACCOUNTS: 'highRiskAccounts',
  EXPIRING_ACCOUNTS: 'expiringAccounts',
  TRENDING_ACCOUNTS: 'trendingAccounts',
  DECLINING_ACCOUNTS: 'decliningAccounts',
  MONTHLY_ACTIVE_USERS: 'monthlyActiveUsers',
  WEEKLY_ACTIVE_USERS: 'weeklyActiveUsers',
  DAILY_ACTIVE_USERS: 'dailyActiveUsers',
  TOP_FEATURES: 'topFeatures',
  PINNED_FEATURES: 'pinnedFeatures',
  VERSIONS: 'versions',
  LICENSE_ASSIGNMENT: 'licenseAssignment',
  LICENSE_ASSIGNMENT_VS_KPI: 'licenseAssignmentVsKpi',
  VERSIONS_VS_KPI: 'versionsVsKpi',
  GEOGRAPHY_WISE_SEATS: 'geographyWiseSeats',
};

const DASHBOARD_PERSONA = {
  general: [
    DASHBOARD_CARDS_ID.ACCOUNT_STATS,
    DASHBOARD_CARDS_ID.HIGH_RISK_ACCOUNTS,
    DASHBOARD_CARDS_ID.EXPIRING_ACCOUNTS,
    DASHBOARD_CARDS_ID.TRENDING_ACCOUNTS,
    DASHBOARD_CARDS_ID.DECLINING_ACCOUNTS,
    DASHBOARD_CARDS_ID.MONTHLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.VERSIONS,
    DASHBOARD_CARDS_ID.TOP_FEATURES,
    DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT,
    DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT_VS_KPI,
  ],
  productOwner: [
    DASHBOARD_CARDS_ID.MONTHLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.WEEKLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.TOP_FEATURES,
    DASHBOARD_CARDS_ID.PINNED_FEATURES,
    DASHBOARD_CARDS_ID.VERSIONS,
    DASHBOARD_CARDS_ID.VERSIONS_VS_KPI,
  ],
  customerExecutive: [
    DASHBOARD_CARDS_ID.ACCOUNT_STATS,
    DASHBOARD_CARDS_ID.HIGH_RISK_ACCOUNTS,
    DASHBOARD_CARDS_ID.EXPIRING_ACCOUNTS,
    DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT,
    DASHBOARD_CARDS_ID.MONTHLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.WEEKLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.PINNED_FEATURES,
  ],
  revenueOperations: [
    DASHBOARD_CARDS_ID.ACCOUNT_STATS,
    DASHBOARD_CARDS_ID.HIGH_RISK_ACCOUNTS,
    DASHBOARD_CARDS_ID.GEOGRAPHY_WISE_SEATS,
    DASHBOARD_CARDS_ID.EXPIRING_ACCOUNTS,
    DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT,
  ],
  marketing: [
    DASHBOARD_CARDS_ID.GEOGRAPHY_WISE_SEATS,
    DASHBOARD_CARDS_ID.TOP_FEATURES,
    DASHBOARD_CARDS_ID.LICENSE_ASSIGNMENT_VS_KPI,
    DASHBOARD_CARDS_ID.MONTHLY_ACTIVE_USERS,
    DASHBOARD_CARDS_ID.DAILY_ACTIVE_USERS,
  ],
};

const ACCOUNT_CARDS_ID = {
  ACCOUNT_STATS: 'accountStats',
  GENERAL: 'general',
  ENGAGEMENT: 'engagement',
  KPI_SCORES: 'kpiScore',
  MAU: 'mau',
  WAU: 'wau',
  TOP_FEATURES: 'topFeatures',
  PINNED_FEATURES: 'pinnedFeatures',
  FEATURE_PARAMETERS: 'featureParameters',
  TOP_CUSTOM_OBJ: 'topCustomObj',
  EVENT_TIMELINE: 'eventTimeline',
};

const ACCOUNT_PERSONA = {
  general: [
    ACCOUNT_CARDS_ID.ACCOUNT_STATS,
    ACCOUNT_CARDS_ID.GENERAL,
    ACCOUNT_CARDS_ID.ENGAGEMENT,
    ACCOUNT_CARDS_ID.KPI_SCORES,
    ACCOUNT_CARDS_ID.MAU,
    ACCOUNT_CARDS_ID.WAU,
    ACCOUNT_CARDS_ID.TOP_FEATURES,
    ACCOUNT_CARDS_ID.PINNED_FEATURES,
    ACCOUNT_CARDS_ID.FEATURE_PARAMETERS,
    ACCOUNT_CARDS_ID.TOP_CUSTOM_OBJ,
    ACCOUNT_CARDS_ID.EVENT_TIMELINE,
  ],
  productOwner: [
    ACCOUNT_CARDS_ID.GENERAL,
    ACCOUNT_CARDS_ID.KPI_SCORES,
    ACCOUNT_CARDS_ID.FEATURE_PARAMETERS,
    ACCOUNT_CARDS_ID.MAU,
    ACCOUNT_CARDS_ID.PINNED_FEATURES,
    ACCOUNT_CARDS_ID.TOP_FEATURES,
  ],
  customerExecutive: [
    ACCOUNT_CARDS_ID.ACCOUNT_STATS,
    ACCOUNT_CARDS_ID.GENERAL,
    ACCOUNT_CARDS_ID.ENGAGEMENT,
    ACCOUNT_CARDS_ID.TOP_FEATURES,
    ACCOUNT_CARDS_ID.WAU,
    ACCOUNT_CARDS_ID.TOP_CUSTOM_OBJ,
    ACCOUNT_CARDS_ID.EVENT_TIMELINE,
  ],
  revenueOperations: [
    ACCOUNT_CARDS_ID.ACCOUNT_STATS,
    ACCOUNT_CARDS_ID.GENERAL,
    ACCOUNT_CARDS_ID.ENGAGEMENT,
    ACCOUNT_CARDS_ID.TOP_FEATURES,
    ACCOUNT_CARDS_ID.WAU,
    ACCOUNT_CARDS_ID.TOP_CUSTOM_OBJ,
    ACCOUNT_CARDS_ID.EVENT_TIMELINE,
  ],
  marketing: [
    ACCOUNT_CARDS_ID.GENERAL,
    ACCOUNT_CARDS_ID.KPI_SCORES,
    ACCOUNT_CARDS_ID.ENGAGEMENT,
    ACCOUNT_CARDS_ID.TOP_FEATURES,
    ACCOUNT_CARDS_ID.MAU,
  ],
};

export {
  URL_CONSTANT,
  DATE_FORMAT,
  BAR_GRAPH,
  TITLES,
  COLUMNS,
  LICENSE_ASSIGNMENT,
  BUTTONS,
  MUTEDTEXT,
  DEFAULT_FILTER,
  DASHBOARD_PERSONA,
  DASHBOARD_CARDS_ID,
  ACCOUNT_PERSONA,
  ACCOUNT_CARDS_ID,
};
